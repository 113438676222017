import React, {FC, useEffect, useState} from "react"
import {useTranslation} from "react-i18next"
import {Box, Button, Hidden, Link, Paper} from "@material-ui/core"
import i18next from "i18next"

import {ReactComponent as CookieInfoIcon} from "../../../assets/svg/cookies.svg"
import ImageByIntegrationType from "../../imageByIntegrationType/ImageByIntegrationType"
import ButtonTextUnderlined from "../buttonTextUnderlined/ButtonTextUnderlined.component"
import {useCookieInfoStyles} from "./CookieInfo.styles"

const COOKIES_ACCEPTED_KEY = "cookiesAccepted"

const CookieInfo: FC = () => {
  const classes = useCookieInfoStyles()
  const [cookiesAccepted, setCookiesAccepted] = useState<boolean>(false)
  const {t} = useTranslation()

  const addCookieConsent = () => {
    localStorage.setItem(COOKIES_ACCEPTED_KEY, "true")
    setCookiesAccepted(true)
  }

  useEffect(() => {
    setCookiesAccepted(localStorage.getItem(COOKIES_ACCEPTED_KEY) !== null)
  }, [])

  if (cookiesAccepted) {
    return null
  }

  return (
    <Paper className={classes.root}>
      <Hidden smDown>
        <Box className={classes.spacer}/>
      </Hidden>
      <Box className={classes.wrapper}>
        <Box className={classes.descriptionWrapper}>
          <Box className={classes.iconWrapper}>
            <ImageByIntegrationType
              imageSrc={<CookieInfoIcon/>}
              imagePath={"cookies.svg"}
              returnSvg={true}
            />
          </Box>
          <p>
            {t("cookieInfo")}
          </p>
        </Box>
        <Box className={classes.buttonsWrapper}>
          <Link
            color="secondary"
            href={`https://telemedi.com/${i18next.language}/privacy-policy/`}
            target="_blank"
            className={classes.privacyPolicy}
            underline="always"
          >
            {t("privacyPolicy")}
          </Link>
          <Button
            color="primary"
            variant="contained"
            onClick={addCookieConsent}
          >
            {t("accept")}
          </Button>
        </Box>
      </Box>
    </Paper>
  )
}

export default CookieInfo
