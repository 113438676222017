import { makeStyles } from "@material-ui/core"

export const useConfettiStyles = makeStyles(() => ({
  container: {
    position: "fixed",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    pointerEvents: "none",
    zIndex: 1000,
    perspective: 700,
    overflow: "hidden"
  },
  "@keyframes confetti-slow": {
    "0%": { transform: "translate3d(0, 0, 0) rotateX(0) rotateY(0)" },
    "100%": { transform: "translate3d(25px, 105vh, 0) rotateX(360deg) rotateY(180deg)" }
  },
  "@keyframes confetti-medium": {
    "0%": { transform: "translate3d(0, 0, 0) rotateX(0) rotateY(0)" },
    "100%": { transform: "translate3d(100px, 105vh, 0) rotateX(100deg) rotateY(360deg)" }
  },
  "@keyframes confetti-fast": {
    "0%": { transform: "translate3d(0, 0, 0) rotateX(0) rotateY(0)" },
    "100%": { transform: "translate3d(-50px, 105vh, 0) rotateX(10deg) rotateY(250deg)" }
  },
  confetti: {
    position: "absolute",
    zIndex: 1,
    top: -10,
    borderRadius: "0%",
    "&.confetti--animation-slow": {
      animation: "$confetti-slow 2.25s linear 1 forwards"
    },
    "&.confetti--animation-medium": {
      animation: "$confetti-medium 1.75s linear 1 forwards"
    },
    "&.confetti--animation-fast": {
      animation: "$confetti-fast 1.25s linear 1 forwards"
    }
  }
}))
