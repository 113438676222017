import React, { FC, useCallback, useEffect, useRef } from "react"

import { useConfettiStyles } from "./Confetti.styles"

interface ConfettiProps {
  isActive: boolean;
  colors?: string[];
  frequency?: number;
}

const DEFAULT_COLORS = ["#EF2964", "#00C09D", "#2D87B0", "#48485E", "#EFFF1D"]
const ANIMATIONS = ["slow", "medium", "fast"]

const Confetti: FC<ConfettiProps> = ({
  isActive,
  colors = DEFAULT_COLORS,
  frequency = 25
}) => {
  const classes = useConfettiStyles()
  const containerRef = useRef<HTMLDivElement>(null)
  const intervalRef = useRef<NodeJS.Timeout>()

  const createConfetti = useCallback(() => {
    if (!containerRef.current) return

    const confetti = document.createElement("div")
    const size = `${(Math.floor(Math.random() * 3) + 7)}px`
    const backgroundColor = colors[Math.floor(Math.random() * colors.length)]
    const left = `${Math.floor(Math.random() * containerRef.current.offsetWidth)}px`
    const animation = ANIMATIONS[Math.floor(Math.random() * ANIMATIONS.length)]

    confetti.classList.add(classes.confetti, `confetti--animation-${animation}`)
    confetti.style.left = left
    confetti.style.width = size
    confetti.style.height = size
    confetti.style.backgroundColor = backgroundColor

    containerRef.current.appendChild(confetti)

    setTimeout(() => {
      confetti.remove()
    }, 3000)
  }, [colors, classes])

  useEffect(() => {
    if (isActive) {
      intervalRef.current = setInterval(createConfetti, frequency)
    }

    return () => {
      if (intervalRef.current) {
        clearInterval(intervalRef.current)
      }
    }
  }, [isActive, createConfetti, frequency])

  if (!isActive) return null

  return <div ref={containerRef} className={classes.container} />
}

export default Confetti
